import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Container } from "@mui/material";
import Title from '../../components/Title';
//import { CouponsAction, clearError } from "../../redux/AuthDuck";
import './Coupons.css';
import { couponTypes, mockCoupons } from "../../constants/coupons";
import Table from "./Table/Table";

const Coupons = props => {

    const [coupon, setCoupon] = useState(null);
    // const couponTypes = [1, 2, 3, 6, 12, "Permanent"];
    // const mockCoupons = {
    //     "10": ["11GY1001", "11GR1002", "11GY1003", "11GR1006", "11GY1012", "11WW1000"],
    //     "20": ["22TP2001", "22YP2002", "22TP2003", "22YP2006", "22TP2012", "22HH2000"],
    //     "30": ["33YG3001", "33ML3002", "33YG3003", "33ML3006", "33YG3012", "33NN3000"],
    //     "40": ["44MP4001", "44AZ4002", "44MP4003", "44AZ4006", "44MP4012", "44BB4000"],
    //     "50": ["55QE5001", "55OL5002", "55QE5003", "55OL5006", "55QE5012", "55FF5000"],
    //     "75": ["77TB7501", "77AP7502", "77TB7503", "77AP7506", "77TB7512", "77TT7500"],
    //     "100": ["99JU10001", "99FF10002", "99JU10003", "99FF10006", "99JU10012", "99CC10000"],
    //     "-50": ["", "", "", "", "SDF50"],
    //     "-100": ["", "", "", "", "FRT100"],
    //     "-200": ["", "", "", "", "HKL200"],
    //     "-300": ["", "", "", "", "KPG300"],
    //     "-400": ["", "", "", "", "FRP400"],
    //     "-500": ["", "", "", "", "ZVM500"],
    //     "-600": ["", "", "", "", "BTJ600"],
    //     "-700": ["", "", "", "", "LWF700"]
    // };

    const onClickCopy = e => {
        copyToClipboard(e.target.innerHTML);
    };

    function copyToClipboard(text) {
        setCoupon(text);
        const textArea = document.createElement('textarea');
        textArea.value = text;
      
        // Make the textarea hidden to avoid displaying it on the page
        textArea.style.position = 'fixed';
        textArea.style.top = '-9999px';
        textArea.style.left = '-9999px';
      
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
      
        // Copy the text to the clipboard
        try {
          const successful = document.execCommand('copy');
          if (successful) {
            //console.log("text copied :", text);
            
          } else {
            console.error('Failed to copy text to clipboard');
          }
        } catch (error) {
          console.error('Failed to copy text to clipboard: ', error);
        }
      
        // Clean up
        document.body.removeChild(textArea);
      }

    return (
        <Container className="coupons">
            <Title title='Cupones' style={{ marginLeft: 26 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <form id="changePass">
                            <div className="cupon-header">
                                {coupon &&
                                    <Typography className="copied-coupon" style={{ marginBottom: '20px' }}>
                                        Copiado : {coupon}
                                    </Typography>
                                }
                            </div>
                            <Table onClickCopy={onClickCopy} />
                        </form>
                    </div>
                </div>
            </Grid>
        </Container>
    );
}

export default withRouter(Coupons);