export const HOME = '/';
export const LOGIN = '/login';
export const FORGET_PASSWORD = '/forget-password';
export const RESET_PASSWORD = '/reset-password';

export const CHANGE_PASSWORD = '/change-password';
export const MANAGE_PLANS = '/manage-plans';
export const COUPONS = '/coupons';
export const REGISTRATION = '/register';
export const CLIENT = '/client';
export const CLIENT_DETAIL = '/client/detail/:id';
export const CLIENT_CREATE = '/client/create';