import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { getPlans, editPlan } from '../../redux/SubscriptionDuck';
import { Grid, Typography, InputLabel, Button, Container } from "@mui/material";
import TextFieldComponent from '../../components/TextField';
import Title from '../../components/Title';
//import { ManagePlansAction, clearError } from "../../redux/AuthDuck";
import './ManagePlans.css';

const ManagePlans = props => {

    const user = useSelector(store => store.auth.user);
    const subPlans = useSelector(store => store.subscription.plans);
    const [subPlansForm, setSubPlansForm] = useState([{}]);
    //const [subPlansForm, setSubPlansForm] = useState(Array.isArray(subPlans) ? [...subPlans] : [{}]);

    const [changeDetected, setChangeDetected] = useState([false, false, false, false, false]);



    const dispatch = useDispatch();
    const onChange = e => {
        var { name, value } = e.target;
        value = value && value.length > 0 ? value : 0;
        value = value && value < 0 ? 0 : value;
        const row = e.target.getAttribute('index');
        ////console.log(subPlansForm);
        const newChangeDetected = [...changeDetected];
        newChangeDetected[row] = true;
        setChangeDetected(newChangeDetected);

        const updatedSubPlans = [...subPlansForm];
        updatedSubPlans[row][name] = value;
        setSubPlansForm(updatedSubPlans);

    };

    const onSubmit = (index) => {
        dispatch(editPlan(subPlansForm[index]));
    };

    const onCancel = (index) => {
        const cleanChangeDetected = [...changeDetected];
        cleanChangeDetected[index] = false;
        setChangeDetected(cleanChangeDetected);

        const resetSubPlans = [...subPlans];
        const currentSubPlansForm = [...subPlansForm];
        currentSubPlansForm[index] = { ...resetSubPlans[index] };
        setSubPlansForm(currentSubPlansForm);

    };

    useEffect(() => {



    }, [subPlansForm]);

    // all this so we subPlansForms does not use the same memory reference from subPlans
    useEffect(() => {
        setChangeDetected([false, false, false, false, false]);
        if (subPlans && typeof subPlans === "object") {
            const newSubPlansForm = [];
            const subPlansValues = Object.values(subPlans);
            for (let i = 0; i < subPlansValues.length; i++) {
                const subPlan = subPlansValues[i];
                const newSubPlan = { ...subPlan };
                newSubPlansForm.push(newSubPlan);
            }
            setSubPlansForm(newSubPlansForm);
        }
    }, [subPlans]);
    useEffect(() => {
        dispatch(getPlans());
    }, [dispatch]);
    /*useEffect(() => {
        //console.log("mockplans", mockplans, Array.isArray(mockplans));
        // dispatch(clearError());
    }, [dispatch, mockplans]);
    */
    return (

        <Container>
            <Title title='Gestionar Planes' style={{ marginLeft: 26 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <form id="changePass">
                            <Typography className="label-generate-password" style={{ marginBottom: '20px' }}>
                                Planes
                            </Typography>
                            <div className="plan-row" >
                                <div className="plan-prices titles">
                                    <div className="plan-clause"><h5>Cantidad de Usuarios</h5></div>
                                    <div className="plan-clause"><h5>Costo por usuario plan mensual</h5></div>
                                    <div className="plan-clause"><h5>Total Plan Mensual</h5></div>
                                    <div className="plan-clause"><h5>Total Plan Anual</h5></div>

                                </div>
                            </div>
                            {(subPlansForm) && Array.isArray(subPlansForm) &&
                                subPlansForm.map((row, index) => (
                                    <div className="plan-row" key={index}>
                                        <InputLabel className="label-app">{row.name}</InputLabel>
                                        <div className="plan-prices">
                                            <TextFieldComponent name="userQuantity"
                                                className="subsText noarrows"
                                                type="number"
                                                maxLength="16"
                                                index={index}
                                                inputProps={{ "min": "0", "maxLength": "16" }}
                                                callback={onChange}
                                                valueInput={row.userQuantity} />
                                            <TextFieldComponent name="perUserCost" type="number"
                                                maxLength="16"
                                                index={index}
                                                inputProps={{ "min": "0", "maxLength": "16" }}
                                                callback={onChange} valueInput={row.perUserCost} />
                                            <TextFieldComponent name="monthlyTotal" type="number"
                                                maxLength="16"
                                                index={index}
                                                inputProps={{ "min": "0", "maxLength": "16" }}
                                                callback={onChange} valueInput={row.monthlyTotal} />
                                            <TextFieldComponent name="yearlyTotal" type="number"
                                                maxLength="16"
                                                index={index}
                                                inputProps={{ "min": "0", "maxLength": "16" }}
                                                callback={onChange} valueInput={row.yearlyTotal} />
                                            <div>
                                                {
                                                    changeDetected && changeDetected[index] &&
                                                    <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                                                        onClick={() => onSubmit(index)}>
                                                        Actualizar
                                                    </Button>
                                                }
                                                {
                                                    changeDetected && changeDetected[index] &&
                                                    <Button variant="contained" className="button-app-cancel"
                                                        onClick={() => onCancel(index)}>
                                                        Cancelar
                                                    </Button>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                ))
                            }
                        </form>
                    </div>
                </div>

            </Grid>
        </Container>
    );
}

export default withRouter(ManagePlans);