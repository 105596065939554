// Base URL
 //const baseURL = 'https://fos-dev.softonitg.com/api/';
//const baseURL = 'http://52.203.7.205:8080/api/';
//const baseURL = 'https://localhost:44379/api/';
const baseURL = 'https://fos-dev.softonitg.com:444/api/';
//Payment Gateway URL
//export const signUpURL = "https://sandbox-merchant.greenpay.me/subscriptions";
export const gpmerchant = "https://sandbox-merchant.greenpay.me/";
export const gpcheckout = "https://sandbox-checkout.greenpay.me/";


export const gpSessionURL = `${baseURL}Subscription/getGPSession`;
export const cardTokenizeURL = `${baseURL}Subscription/getCardToken`;
export const registerSubscriptionURL = `${baseURL}Subscription/create`;

export const submitSubscriptionURL = `${baseURL}Subscription/submitSubscription`;
export const getPaymentsURL = `${baseURL}Subscription/payments/id`;
export const getSubscriptionPlansURL = `${baseURL}Subscription/subscriptionplans`;
export const updateSubscriptionPlanURL = `${baseURL}Subscription/editplan`;
export const getSubscriptionByIdURL = `${baseURL}Subscription/id`;



// Auth URLs
export const loginURL = `${baseURL}Authenticate/login`;
export const forgotPasswordURL = `${baseURL}Authenticate/forgotPassword`;
export const resetPasswordURL = `${baseURL}Authenticate/resetPassword`;
export const getUserURL = `${baseURL}User/currentUser`;
export const changePasswordURL = `${baseURL}User/changePassword`;
export const managePlansURL = `${baseURL}User/managePlans`;

export const getOrganizationsURL = `${baseURL}OrganizationSetting`;
export const getOrganizationIdURL = `${baseURL}OrganizationSetting/with-admin`;
export const getOrganizationDetailURL = `${baseURL}OrganizationSetting/detail`;
export const getOrganizationDisableURL = `${baseURL}OrganizationSetting/disable?id=`;
export const getOrganizationActiveURL = `${baseURL}OrganizationSetting/activate?id=`;
export const postOrganizationsURL = `${baseURL}OrganizationSetting/create`;
export const putOrganizationsURL = `${baseURL}OrganizationSetting/edit`;
export const getCountriesENURL = `${baseURL}CountryCode/en`;
export const getAppSettingByIdURL = `${baseURL}AppSetting/id`;
export const updateOrgWebsiteURL = `${baseURL}OrganizationSetting/editWebsite`;
export const putAppSettingURL = `${baseURL}AppSetting/edit`;

export const getLenguageURL = `${baseURL}Language`;
export const getFileURL = `${baseURL}File/preview?id=`;
