import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Accordion, AccordionDetails, AccordionSummary, Alert } from '@mui/material';
import TextFieldComponent from '../../components/TextField';
import SelectFieldComponent from "../../components/SelectField";
import Loading from '../../components/Loading';
//import Logo from '../../assets/Images/Login.png';
import SwitchToggleComponent from "../../components/SwitchToggle";
import Checkbox from "../../components/Checkbox";
import { signUpAction,getPlans } from '../../redux/SubscriptionDuck';
//import { useTranslation, initReactI18next } from "react-i18next";
import subscriptionTranslations from "../../assets/en_messages/subscription.json"
import { getCountriesEN } from "../../redux/CountryDuck";
import { Validation } from "./SubscriptionValidation";
import './Registration.css';
import Logo from '../../assets/Logos/Logo.png';
import { couponTypes, mockCoupons } from '../../constants/coupons';

const Registration = props => {

    //const { t } = useTranslation([ 'subscription']);
    function t(key) {
        return subscriptionTranslations[key];
    }
    const [checkoutVisible, setCheckoutVisible] = useState(false);
    const countries = useSelector(store => store.country.countries);
    const subPlans = useSelector(store => store.subscription.plans);
    const checkoutError = useSelector(store => store.subscription.alertMessage);
    const redirectToThankYou = useSelector(store => store.subscription.redirectToThankYou);
                              
    const inputRef = useRef(null);
    const [monthlyBilling, setMonthlyBilling] = useState(true);
    const [termsAcceptance, setTermsAcceptance] = useState(false);
    const [subscriptionError, setSubscriptionError] = useState({});
    const [code, setCode] = useState('');
    const [mockplans,setMockplans] = useState([{}]);

    //const [checkoutError, setCheckoutError] = useState("");
    const [selectedPlan, setSelectedPlan] = useState({
        "Id":"",
        "name": "",
        "userQuantity": '',
        "costo_por_usuario_plan_mensual": '',
        "costo_por_usuario_plan_anual": '',
        "monthlyTotal": '',
        "yearlyTotal": '',
        "total_final": ''
    });
/*
    const [subscription, setSubscription] = useState({
        firstName: 'Juan',
        lastName: 'Alpizar',
        email: 'juanfe_a14@outlook.com',
        orgName: 'RPLJ',
        countryCodeId: 12,
        countryName: 'CRC',
        phoneNumber: '87046694',
        cardHolderName: 'Juan Felipe A',
        cardN: '4242424242424243',
        exp: "2023-12",
        CVV: '424',
        discountPeriod: '',
        termsAcceptance: true,
        subtotal: '650',
        planId:0,
        expMonth:2,
        expYear:2025,
        planName: 'startup',
        final: '0'
    });
    /*/
    const [mocksplans,setMocksplans] = useState([
        {
            "name": "Startup",
            "Id": 1,
            "userQuantity": 5,
            "costo_por_usuario_plan_mensual": 29,
            "costo_por_usuario_plan_anual": 29,
            "monthlyTotal": 145,
            "yearlyTotal": 1450
        },
        {
            "name": "Basic",
            "Id": 2,
            "userQuantity": 10,
            "costo_por_usuario_plan_mensual": 27,
            "costo_por_usuario_plan_anual": 27,
            "monthlyTotal": 270,
            "yearlyTotal": 2700
        },
        {
            "name": "Standard",
            "Id": 3,
            "userQuantity": 25,
            "costo_por_usuario_plan_mensual": 25,
            "costo_por_usuario_plan_anual": 25,
            "monthlyTotal": 625,
            "yearlyTotal": 6250
        },
        {
            "name": "Corporate",
            "Id": 4,
            "userQuantity": 50,
            "costo_por_usuario_plan_mensual": 23,
            "costo_por_usuario_plan_anual": 23,
            "monthlyTotal": 1150,
            "yearlyTotal": 11150
        },
        {
            "name": "Enterprise",
            "Id": 5,
            "userQuantity": +50,
            "costo_por_usuario_plan_mensual": 21,
            "costo_por_usuario_plan_anual": 21,
            "monthlyTotal": "-",
            "yearlyTotal": "-"
        },
    ]);
    const [subscription, setSubscription] = useState({
        firstName: '',
        lastName: '',
        email: '',
        orgName: '',
        countryCodeId: 0,
        countryName: '',
        phoneNumber: '',
        cardHolderName: '',
        cardN: '',
        exp: '',
        expYear: '',
        expMonth: '',
        CVV: '',
        termsAcceptance: false,
        subtotal: '',
        discountPeriod: '',
        planId:0,
        planName: '',
        final: ''
    });
    //*/
    const [discountTerm, setDiscountTerm] = useState(0);
    const [discountRatio, setDiscountRatio] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [form, setForm] = useState({
        type: 'text', required: false
    });

    const dispatch = useDispatch();

    useEffect(() => {
        //console.log("check error",checkoutError)
        //console.log("redirect?",redirectToThankYou,)
    }, [redirectToThankYou,checkoutError]);
   
    useEffect(() => {
        const chosenPlan = mockplans && mockplans.find((p) => p.name === selectedPlan.name);

        let subtotal = "$" + (selectedPlan && monthlyBilling ?
            selectedPlan.monthlyTotal : selectedPlan.yearlyTotal);

       setForm({ ...form, subtotal: subtotal });
       setSubscription({ ...subscription, planName: selectedPlan.name });
       ////console.log("planId",selectedPlan.Id,chosenPlan);
       setSubscription({ ...subscription, final:parseFloat(selectedPlan.total_final).toFixed(2) });
    }, [selectedPlan,monthlyBilling,subscription]);
    useEffect(() => {
        setSubscription({ ...subscription, termsAcceptance: termsAcceptance });
    }, [termsAcceptance]);

    useEffect(() => {
        //console.log ("discount",discount,discountTerm);
        UpdateTotal();
        setSubscription({
            ...subscription,
            discountPeriod: discountTerm ? discountTerm : 0,
            discountTerm: discountTerm ? discountTerm : 0,
            discount: discount.toFixed(2),
          });
         
    }, [discount, discountTerm]);

   
    useEffect(() => {
       setMockplans(subPlans);
    }, [subPlans]);
    useEffect(() => {
        let sub = subscription;
        Object.assign(sub, form);
        setSubscription(sub);
        ////console.log(form, sub);
    }, [form]);

    useEffect(() => {
        dispatch(getCountriesEN());
        dispatch(getPlans());
    }, [dispatch]);

    useEffect(() => {
        // //console.log("monthly billing?",monthlyBilling);
        if (selectedPlan.total_final) {
            UpdateTotal();
        }
        //       dispatch(clearError());
    }, [monthlyBilling]);

    const onChangeCode = e => {
        cleanDiscount();
        const { name, value } = e.target;
        setCode(value);
    };
    const onChangeForm = e => {
        var { name, value } = e.target;
       
        let validVal = true;
        if (e.target.type=="number"){
            
            if(e.target.maxLength && value.length>=e.target.maxLength){
                value = value.substring(0,e.target.maxLength)
            }
        }
       
        validVal && setForm({ ...form, [name]: value });
    };

    const onChangeFormCountry = e => {
        setForm({ ...form, countryCodeId: e.target.value });
    };

    const onSubscribe = e => {
        const error = Validation(subscription, t);
        setSubscriptionError(error);
        //console.log("sub:", subscription);
        ////console.log("selectedPlan:", selectedPlan);
        ////console.log("errors:", error);
        if (Object.entries(error).length === 0) {
           dispatch(signUpAction(subscription,monthlyBilling,selectedPlan.userQuantity,selectedPlan.id));
        } else {
            document.querySelector('#subscription-accordion').scrollIntoView({
                behavior: 'smooth'
            });
        }
    };

    const handleToggle = () => {
        cleanDiscount();
        setMonthlyBilling(!monthlyBilling);
    };

    const handleToggleTerms = (e) => {
        setTermsAcceptance(!termsAcceptance);
    };

    function UpdateTotal(currentSelection = "") {
        if (!currentSelection) {
            currentSelection = selectedPlan;
        }
        let final = monthlyBilling ? currentSelection.monthlyTotal : currentSelection.yearlyTotal;
        ////console.log("final", final);
        if (discountRatio) {
            final = final - ApplyDiscount()
        }
        ////console.log("final discounted", final);
        const updatedSelectedPlan = { ...currentSelection, total_final: final }
        setSelectedPlan(updatedSelectedPlan);
    }

    const handleCouponInput = (e, coupon) => {
        
        const { value } = e.target;
        ////console.log ("selection",value);
        if (value && value.length>0){
            const found = value && Object.entries(mockCoupons).find(([key, array]) => array.includes(value));
            if (found && (!monthlyBilling ? found[1].indexOf(value) === 5 : true)) {
                let amount = found[0] / 100;
                setDiscountRatio(amount);
                let termIndex = found[1].indexOf(value);
                let term = couponTypes[termIndex];
                setDiscountTerm(term);
                let subtotal = (monthlyBilling ? selectedPlan.monthlyTotal : selectedPlan.yearlyTotal)
                let planTotal = 0;
                planTotal = roundToNearestFive(subtotal * (1 - amount))
                setDiscount(subtotal - planTotal);
                setSubscriptionError({ ...subscriptionError, code: "" });
    
            } else {
                cleanDiscount();
                setSubscriptionError({ ...subscriptionError, code: "Coupon is invalid or expired, please check it is still valid." });
            }

        }
        else {cleanDiscount()}
    }
    function cleanDiscount(){
        setCode("");
        setDiscountRatio(0);
        setDiscountTerm(0);
        setDiscount(0);
    
    }
    function ApplyDiscount() {
        let amount = discountRatio;
        let subtotal = (monthlyBilling ? selectedPlan.monthlyTotal : selectedPlan.yearlyTotal)
        let planTotal = 0;
        planTotal = roundToNearestFive(subtotal * (1 - amount))
        setDiscount(subtotal - planTotal);
        //console.log(amount, "1-amount", (1 - amount), "total", planTotal, "total discounted", planTotal * (1 - amount),subtotal - planTotal)
        return (subtotal - planTotal);
    }

    const expandCheckout = (value) => {
        cleanDiscount();
        UpdateTotal(mockplans.at(value));
        setCheckoutVisible(true);
        document.querySelector('#subscription-accordion').scrollIntoView({
            behavior: 'smooth'
        });
    }

    function roundToNearestFive(num) {
        return Math.round(num * 20) / 20;
    }

    const colors = [
        { "band-color": "#16acea", "title-color": "#fff" },
        { "band-color": "#5ab6df", "title-color": "#fff" },
        { "band-color": "#bee3e9", "title-color": "#959393" },
        { "band-color": "#aba3d0", "title-color": "#fff" },
        { "band-color": "#8976b6", "title-color": "#fff" },
        { "band-color": "#fff", "title-color": "#fff" }
    ];

   

    const minDate = () => {
        const actualDate = new Date().toISOString().split("T")[0];
        const splitDate = actualDate.split("-", 2)
        return splitDate[0] + "-" + splitDate[1];
    };

    if (!redirectToThankYou) {
        return (
            <div className="registration-body registrarion" style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                <div className='registration-section'>
                    <div className='reg-div header'>
                        <div className="header-logo"><img src={Logo} alt="Logo" /></div>
                        <div className="header-nav">
                            <a href="https://fos.services/"> Back Home</a>
                        </div>
                    </div>
                    <div className='reg-div title'>
                        <h5>You're going to love FOS</h5>
                        <h3>Choose your subscription plan</h3>
                    </div>
                    <div className='reg-div-selector'>
                        <span className={monthlyBilling ? "active" : "disabled"}>Monthly</span>
                        <SwitchToggleComponent defaultChecked={false} callback={handleToggle} />
                        <span className={!monthlyBilling ? "active" : "disabled"}>Yearly</span>
                    </div>
                    <div className='reg-div-yearly-offer' >
                        <h3 style={{ opacity: monthlyBilling ? 0 : 1 }} >2 Months Free </h3>
                    </div>
                    <div className="cards-container">
                        <div className='reg-div cards'>
                            {(mockplans) && Array.isArray(mockplans) &&
                                mockplans.map((row, index) => (
                                    <div className='reg-card-container' key={index}>
                                        <div className="reg-card">
                                            <div className='plan-title' style={{ backgroundColor: colors[index]["band-color"] }}>
                                                <div className="plan-label-app" style={{ fontSize: 'inherit', color: colors[index]["title-color"] }}>{row.name}</div>
                                            </div>
                                            <div className="plan-prices">
                                                <div className='perUserCost'>${monthlyBilling ? row.perUserCost : row.perUserCost}<span>/user</span></div>
                                                <div className='maxUsers'>{index === 4 ? "More than "+mockplans[index-1].userQuantity : "Up to " + row.userQuantity}  users </div>
                                                <div> Unlimited mobile users </div>
                                                {
                                                    index !== 4 ? <div className='totalMonthly'>Total {monthlyBilling ? "Monthly" : "Annual"} : ${monthlyBilling ? row.monthlyTotal : row.yearlyTotal}</div>
                                                        :
                                                        <div className='totalMonthly'> Contact Us </div>
                                                }
                                            </div>
                                        </div>
                                        {
                                            index !== 4 ?
                                                <Button variant="contained" value={index} className="button-app-save" onClick={() => expandCheckout(index)}>
                                                    <span>Subscribe  </span>
                                                </Button>
                                                :
                                                <Button variant="contained" value={index} className="button-app-save" >
                                                    <a style={{ textDecoration: "none", color: 'white' }} href="https://fos.services/#contact">Contact Us</a>
                                                </Button>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <Accordion className="accordion-sumary-row-title" id="subscription-accordion" expanded={checkoutVisible} ref={inputRef} >
                    <AccordionSummary style={{ display: 'none' }}>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='registration-section subscription-data' >
                            <div className='subscription column'>
                                <div className="subscriptionformField">
                                    <label className=" subscription-form label">First Name</label>
                                    <TextFieldComponent name="firstName"
                                        className="subsText"
                                        maxLength={30}
                                        type="text"
                                        valueInput={form.firstName}
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.firstName}
                                    />
                                </div>
                                <div className="subscriptionformField">
                                    <label className=" subscription-form label">Last Name</label>
                                    <TextFieldComponent name="lastName"
                                        className="subsText"
                                        maxLength={30}
                                        type="text"
                                        valueInput={form.lastName}
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.lastName}
                                    />
                                </div>
                                <div className="subscriptionformField">
                                    <label className=" subscription-form label">Email</label>

                                    <TextFieldComponent name="email"
                                        className="subsText"
                                        type="text"
                                        maxLength={100}
                                        valueInput={form.email}
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.email}
                                    />
                                </div>
                                <div className="subscriptionformField">
                                    <label className=" subscription-form label">Organization Name</label>
                                    <TextFieldComponent name="orgName"
                                        className="subsText"
                                        type="text"
                                        valueInput={form.orgName}
                                        maxLength={70}
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.orgName}
                                    />
                                </div>
                                <div className="subscriptionformField">
                                    <label className=" subscription-form label">Country</label>
                                    <SelectFieldComponent
                                        className="subscriptionCountries"
                                        name="countryCodeId"
                                        //onChange={onCountryChange.bind(this)}
                                        onChange={onChangeFormCountry}
                                        valueInput={form.countryCodeId}
                                        options={[...countries]}
                                        errorInput={subscriptionError.countryCode}
                                    />
                                </div>
                                <div className="subscriptionformField" style={{ marginTop: "40px" }}>
                                    <Checkbox
                                        name="termsAcceptance"
                                        value={!termsAcceptance}
                                        checkboxCheckedValue={false}
                                        errorInput={subscriptionError.termsAcceptance}
                                        onChange={handleToggleTerms}>
                                    </Checkbox>
                                    <div className="terms-and-conditions">
                                        I understand and accept the <a target="_blank" rel="noreferrer" href="https://fos.services/fos-terms-of-use-and-service-agreement/">Terms & Conditions </a>
                                    and <a target="_blank" rel="noreferrer" href="https://fos.services/privacy-policy/"> Privacy Policy</a>
                                    </div>
                                </div>
                                <p className='app-error-text'>{subscriptionError.termsAcceptance}</p>
                            </div>
                            <div className='subscription column'>
                                <div className="subscriptionformField">
                                    <label className=" subscription-form label">Subtotal</label>
                                    <TextFieldComponent name="subtotal"
                                        className="subsText noBorder"
                                        type="montextth"
                                        valueInput={form.subtotal}
                                        errorInput={subscriptionError.subtotal}
                                        readOnly
                                    />
                                </div>
                                <div className="subscriptionformField">
                                    <label className=" subscription-form label">Redeem Code</label>
                                    <TextFieldComponent
                                        className="subsText"
                                        name="Code"
                                        type="text"
                                        valueInput={code}
                                        callback={onChangeCode}
                                        onBlur={handleCouponInput}
                                        errorInput={subscriptionError.code}
                                    />
                                </div>
                                <div className="subscriptionformField">
                                    <label className=" subscription-form label">Name on Card</label>
                                    <TextFieldComponent name="cardHolderName"
                                        className="subsText"
                                        type="text"
                                        valueInput={form.cardHolderName}
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.cardHolderName}
                                    />
                                </div>
                                <div className="subscriptionformField">
                                    <label className=" subscription-form label">Card Number</label>
                                    <TextFieldComponent name="cardN"
                                        className="subsText noarrows"
                                        type="number"
                                        maxLength="16"
                                        inputProps = {{
                                            "min":"0",
                                            
                                            "maxLength":"16"
                                         }}
                                        valueInput={form.cardN}
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.cardN}
                                    />
                                </div>
                                <div className="subscriptionformField">
                                    <label className=" subscription-form label">CVV</label>
                                    <TextFieldComponent name="CVV"
                                        className="subsText noarrows"
                                        type="number"
                                        valueInput={form.CVV}
                                        maxLength="4"
                                        inputProps = {{
                                            "min":"1",
                                            
                                            "maxLength":"4"
                                         }}
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.CVV}
                                    />
                                </div>
                                <div className="subscriptionformField" style={{display: 'flex',
alignItems: 'flex-start',
marginTop: '34px'}}>
                                    
                                    <label className=" subscription-form label">Expiration Date</label>
                                    <div class="expiration-selectors">
                                        <div>

                                    <label className=" subscription-form label">Month</label>
                                    <TextFieldComponent name="expMonth"
                                        className="subsText"
                                        type="number"
                                        valueInput={form.expMonth}
                                        min="01"
                                        max="12"
                                        placeholder="MM"
                                        maxLength="2"
                                        inputProps = {{
                                            "min":"0",
                                            
                                            "maxlength":"2"
                                         }}
                                        inputmode="numeric" 
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.expMonth}
                                    />  
                                        </div>
                                        <div>

                                     <label className=" subscription-form label">Year</label>
                                    <TextFieldComponent name="expYear"
                                        className="subsText"
                                        type="number"
                                        valueInput={form.expYear}
                                        placeholder="YYYY"
                                        min="2023"
                                        maxLength="4"
                                        inputmode="numeric" 
                                        inputProps = {{
                                            "min":"2023",
                                            
                                            "maxlength":"4"
                                         }}
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.expYear}
                                    />    
                                        </div>
                                      
                                    </div>
                                </div>
                                {discount > 0 &&
                                    <div className="subscriptionformField">
                                        <label className=" subscription-form label">Discount Applied</label>
                                        <TextFieldComponent name="discount"
                                            className="noBorder"

                                            type="text"
                                            valueInput={"$" + discount.toFixed(2)}
                                            readOnly
                                        />
                                    </div>
                                }
                                {discountTerm !== 0 &&
                                    <>
                                        <div className="subscriptionformField">
                                            <label className=" subscription-form label">Discount Period</label>
                                            <TextFieldComponent name="period"
                                                className="noBorder"
                                                type="text"
                                                valueInput={discountTerm + (discountTerm !== "Permanent" ? " months" : "")}
                                                readOnly
                                            />
                                        </div>
                                        <span>
                                            Once the term of your redeem code ends, the full amount of your subscription will be charged.
                                        </span>
                                    </>
                                }
                                {
                                    checkoutError &&
                                    <Alert severity="error" className='change-pass-alert'> {checkoutError}</Alert>
                                }
                                <div className="subscriptionformField total">
                                    <label className=" subscription-form label full total">
                                        {monthlyBilling ? "Total Monthly Due" : "Total Annual Due"}
                                    </label>
                                    <span name="finalChargeDue">{"$" + parseFloat(selectedPlan.total_final).toFixed(2)}</span>
                                </div>
                                <Loading />
                                <Button variant="contained" className="button-app-submit"
                                    onClick={() => onSubscribe()}
                                >
                                    Submit and Subscribe
                                </Button>
                                <div className="greenpay">
                                    Card Processing by <a target="_blank" rel="noreferrer" href="https://greenpay.me">Greenpay</a>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <div className='registration-section footer'>
                    <span className="footer-info">
                        Copyrights Reserved FOS Systems – 2023
                    </span>
                </div>
            </div>
        );
    }
    else{
      window.location.href = 'http://fos.services/thankyou';
      //return null;
    }
}

export default withRouter(Registration);