import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router, Route, Switch,
  useLocation
} from 'react-router-dom';
import { ProtectedRoute } from './services/Middleware';
import Loading from './components/Loading';
import Sidebar from './components/Sidebar';
import Login from './views/Login';
import ForgetPassword from './views/ForgetPassword';
import ResetPassword from './views/ResetPassword';
import ChangePassword from './views/ChangePassword';
import ManagePlans from './views/ManagePlans';
import Coupons from './views/Coupons';
import Client from './views/Client';
import Registration from './views/Registration';
import CreateClient from './views/Client/Create';
import DetailClient from './views/Client/Detail';
import SnackbarResponse from './components/SnackbarResponse';
import * as ROUTES from './constants/routes';
import { logoutAction } from './redux/AuthDuck';
import './App.css';

const NoMatch = () => {
  const location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}



const App = () => {

  const isLogged = useSelector(store => store.auth.isLogged);
  const expiration = useSelector(store => store.auth.expiration);
  const dispatch = useDispatch();

  const tokenExpiration = () => {
    if (isLogged) {
      const expirationDate = new Date(expiration);
      const actualDate = new Date();
      if (expirationDate.getTime() < actualDate.getTime()) {
        dispatch(logoutAction());
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(tokenExpiration, 6000);
    return () => clearTimeout(timer);
  });

  return (
    <div>
      <Loading />
      <SnackbarResponse />
      <Router>
        <Switch>
          <Route exact path={ROUTES.LOGIN} component={Login} />
          <Route exact path={ROUTES.REGISTRATION} component={Registration} />
          <Route exact path={ROUTES.FORGET_PASSWORD} component={ForgetPassword} />
          <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
          <Sidebar>
            <ProtectedRoute exact path={ROUTES.CHANGE_PASSWORD} component={ChangePassword} />
            <ProtectedRoute exact path={ROUTES.HOME} component={Client} />
            <ProtectedRoute exact path={ROUTES.CLIENT} component={Client} />
            <ProtectedRoute exact path={ROUTES.MANAGE_PLANS} component={ManagePlans} />
            <ProtectedRoute exact path={ROUTES.COUPONS} component={Coupons} />
            <ProtectedRoute exact path={ROUTES.CLIENT_CREATE} component={CreateClient} />
            <ProtectedRoute exact path={ROUTES.CLIENT_DETAIL} component={DetailClient} />
          </Sidebar>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Router>
    </div>
  )
};

export default App;
