import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Typography, InputLabel, CardMedia, Button } from "@mui/material";
import Title from "../../../components/Title";
import TextFieldComponent from '../../../components/TextField';
import * as ROUTES from '../../../constants/routes';
import { createClient } from '../../../redux/ClientDuck';
import { validateClient } from './FormValidation';
import './Form.css';

const FormClient = props => {

    const isCreated = useSelector(store => store.client.isCreated);

    const [company, setCompany] = useState({
        name: '', image: '', imageUrl: '',
        firstName: '', lastName: '', email: '', numberLicences: '',
        url_android: '', url_ios: '',defaultURL:''
    });
    const [companyError, setCompanyError] = useState({});
    const dispatch = useDispatch();

    const onChange = e => {
        const { name, value } = e.target;
        setCompany({ ...company, [name]: value });
    };

    const onImageChange = async event => {
        if (!!event.target.files[0]) {
            const { name } = event.target;
            const file = event.target.files[0];
            let img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
                if (img.width <= 400 && img.height <= 200) {
                    setCompany({
                        ...company,
                        [name]: file,
                        imageUrl: URL.createObjectURL(file)
                    });
                    setCompanyError({ ...companyError, image: '' });
                } else {
                    setCompanyError({ ...companyError, image: `Dimensiones establecidas no válidas (${img.width} x ${img.height})` });
                }
            }

        }
    };

    const onSubmit = () => {
        const clientError = validateClient(company);
        setCompanyError(clientError);
        if (Object.entries(clientError).length === 0) {
            dispatch(createClient(company));
        }
    };

    useEffect(() => {
        (isCreated) && props.history.push(ROUTES.CLIENT);
    }, [isCreated, props.history]);

    return (
        <Container>
            <Title title="Empresa" style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <Typography className="app-form-title">
                        Datos de la empresa
                    </Typography>
                    <Typography className="app-form-subtitle">
                        Agregue los datos de la empresa
                    </Typography>
                    <div style={{ width: '50%', marginTop: 24 }}>
                        <InputLabel className="label-app">Nombre de la empresa</InputLabel>
                        <TextFieldComponent name="name"
                            maxLength={25}
                            placeholder="Nombre de la empresa"
                            valueInput={company.name}
                            callback={onChange.bind(this)}
                            errorInput={companyError.name}
                        />
                    </div>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">Logotipo (Opcional)</Typography>
                    <div className='image-div'>
                        {company.imageUrl &&
                            <CardMedia component="img"
                                image={company.imageUrl}
                                className="card-img-form"
                            />
                        }
                        <Button component="label" className='button-add-image'>
                            <input type="file" accept="image/*"
                                name="image" onChange={onImageChange}
                                style={{ display: "none" }}
                            />
                                Agregar Imagen
                        </Button>
                        <br />
                        <Typography className="add-image-title">Dimensiones Válidas: 400 x 200 px</Typography>
                    </div>
                    <p className='app-error-text'>{companyError.image}</p>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">
                        Agregar administrador
                    </Typography>
                    <Typography className="app-form-subtitle">
                        Ingrese el nombre y correo del administrador al que desea enviar la invitación
                    </Typography>
                    <div style={{
                        display: 'flex', flexDirection: 'row', marginTop: 32,
                        width: '65%', justifyContent: 'space-between'
                    }}>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">Nombre</InputLabel>
                            <TextFieldComponent name="firstName"
                                maxLength={25}
                                placeholder="Nombre"
                                valueInput={company.firstName}
                                callback={onChange.bind(this)}
                                errorInput={companyError.firstName}
                            />
                        </div>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">Apellidos</InputLabel>
                            <TextFieldComponent name="lastName"
                                placeholder="Apellidos"
                                maxLength={30}
                                valueInput={company.lastName}
                                callback={onChange.bind(this)}
                                errorInput={companyError.lastName}
                            />
                        </div>
                    </div>
                    <div style={{ width: '50%', marginTop: 24 }}>
                        <InputLabel className="label-app">Email</InputLabel>
                        <TextFieldComponent name="email"
                            placeholder="Email"
                            valueInput={company.email}
                            callback={onChange.bind(this)}
                            errorInput={companyError.email}
                            type='email'
                        />
                    </div>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">
                        Subdominios
                    </Typography>
                    <div style={{ width: '50%', marginTop: 24 }}>
                        <InputLabel className="label-app">Subdominio por defecto</InputLabel>
                        <TextFieldComponent name="defaultURL"
                            placeholder="https://"
                            valueInput={company.defaultURL}
                            callback={onChange.bind(this)}
                            errorInput={companyError.URL}
                        />
                    </div>
                  
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">
                        Licencias
                    </Typography>
                    <div style={{ width: '50%', marginTop: 24 }}>
                        <InputLabel className="label-app">Cantidad de licencias</InputLabel>
                        <TextFieldComponent name="numberLicences"
                            placeholder="Cantidad de licencias"
                            valueInput={company.numberLicences}
                            callback={onChange.bind(this)}
                            errorInput={companyError.numberLicences}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                        onClick={() => onSubmit()}>
                        Guardar
                    </Button>
                    <Button variant="contained" className="button-app-cancel"
                        component={Link} to={ROUTES.CLIENT}>
                        Cancelar
                    </Button>
                </div>
            </Grid>
        </Container>
    );
}
export default withRouter(FormClient);