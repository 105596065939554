import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
    backdrop: {
        zIndex: 10000,
        color: '#FFF',
    },
}));

const Loading = () => {

    const classes = useStyles();
    const subscriptionLoading = useSelector(store => store.subscription.loading);

    const active = () => {
        return !!( subscriptionLoading);
    };

    return (
        <Backdrop className={classes.backdrop} open={active()}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default Loading;