import React, { useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import RemoveRedEye from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import './TextField.css';

const TextFieldComponent = (props) => {

    const { name, valueInput, row, errorInput, maxLength = 50, index,
        disabled, callback, onBlur, type = 'text', placeholder, Icon = null, position } = props;
    const [passwordIsMasked, setPasswordIsMasked] = useState(true);

    /**
     * This method is to send a response when the input value changes 
     * @param {*} event Method event
     */
    const onChange = event => {
        callback(event);
    };

    const onBlurCallback = event => {
       onBlur && onBlur(event);
    }

    const togglePasswordMask = () => {
        setPasswordIsMasked(!passwordIsMasked);
    };

    return type === 'password' ? (
        <TextField
            id={`outlined-basic ${name}`}
            variant="outlined"
            margin="normal"
            placeholder={placeholder}
            required
            fullWidth
            name={name}
            value={valueInput}
            autoComplete='current-password'
            onChange={onChange}
            type={passwordIsMasked ? 'password' : 'text'}
            className="input-texfield"
            error={!!errorInput}
            helperText={errorInput}
            inputProps={{
                maxLength: maxLength,
            }}
            onBlur={onBlurCallback}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                        {passwordIsMasked
                            ?
                            <RemoveRedEye
                                onClick={togglePasswordMask}
                            />
                            :
                            <VisibilityOffIcon
                                onClick={togglePasswordMask}
                            />
                        }
                    </InputAdornment>
                ),
            }}
        />
    ) : (
            <TextField
                id={`outlined-basic ${name}`}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type={type}
                placeholder={placeholder}
                name={name}
                value={valueInput}
                autoComplete={name}
                onChange={onChange}
                className={`input-texfield ${Icon ? 'icon' : ''}`}
                multiline={!!row}
                index={index}
                rows={row}
                error={!!errorInput}
                helperText={errorInput}
                disabled={disabled}
                inputProps={{
                    maxLength: maxLength,
                    
                    ...props
                }}
                InputProps={
                    Icon &&
                        position === 'start' ?
                        {
                            startAdornment: (
                                <InputAdornment position="start" style={{ cursor: 'pointer' }}>
                                    {Icon}
                                </InputAdornment>
                            )
                        }
                        :
                        {
                            endAdornment: (
                                <InputAdornment position="start" style={{ cursor: 'pointer' }}>
                                    {Icon}
                                </InputAdornment>
                            )
                        }
                }
            />
        );
};

export default TextFieldComponent;