import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
    Drawer, MenuList, MenuItem
} from '@mui/material';
import Navbar from '../Navbar';
import { ReactComponent as Client } from '../../assets/Icons/client.svg';
import IconSvg from '../../components/Icon';
import * as ROUTES from '../../constants/routes';
import './Sidebar.css';

const Sidebar = props => {
    const { location: { pathname }, children } = props;

    const drawer = (
        <MenuList className="menu-list-sidebar">
            <MenuItem className="item-sidebar"
                component={Link} to={ROUTES.CLIENT}
                selected={
                    pathname.includes(ROUTES.CLIENT) ||
                    pathname === ROUTES.HOME
                }
            >
                <IconSvg Icon={Client} className="item-sidebar-icon-svg item-sidebar-icon" />
                Clientes
            </MenuItem>
            <MenuItem className="item-sidebar"
                component={Link} to={ROUTES.MANAGE_PLANS}
                selected={pathname.includes(ROUTES.MANAGE_PLANS)}
            >
                <IconSvg Icon={Client} className="item-sidebar-icon-svg item-sidebar-icon" />
                Gestionar Planes
            </MenuItem>
            <MenuItem className="item-sidebar"
                component={Link} to={ROUTES.COUPONS}
                selected={pathname.includes(ROUTES.COUPONS)}
            >
                <IconSvg Icon={Client} className="item-sidebar-icon-svg item-sidebar-icon" />
                Seleccionar Cupón
            </MenuItem>
        </MenuList>
    );

    return (
        <div>
            <Navbar />
            <div style={{ display: 'flex' }}>

                <div>
                    <Drawer variant="permanent" open
                        className="drawer"
                    >
                        {drawer}
                    </Drawer>
                </div>
                <div style={{
                    margin: '100px 0 5%',
                    width: 'calc(100vw - 440px)'
                }}>
                    {children}
                </div>
            </div>
        </div>
    )
};

export default withRouter(Sidebar);