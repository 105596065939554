import React from "react";
import { withRouter } from "react-router";
import {
    TableContainer, TableRow,
    TableCell, Table, TableHead, TableBody
} from "@mui/material";
import './Table.css';
import { couponTypes, mockCoupons } from "../../../constants/coupons";

const TableClient = ({ onClickCopy }) => {
    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{textAlign: "center"}} className='table-app-title-header'>Discount</TableCell>
                        {(couponTypes) && Array.isArray(couponTypes) && couponTypes.map((row, index) => (
                            <TableCell style={{textAlign: "center"}} className='table-app-title-header'>{row}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(mockCoupons).length === 0 &&
                        <TableRow key='empty'>
                            <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                No hay cupones
                            </TableCell>
                        </TableRow>
                    }
                    {Object.entries(mockCoupons).map(([category, codes]) => (
                        <TableRow key={category}>
                            <TableCell style={{textAlign: "center"}} className='table-row-name'>
                                {category}%
                                </TableCell>
                            {codes.map(code => (
                                <TableCell style={{textAlign: "center"}} className='table-row-name' onClick={onClickCopy}>
                                    {code}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default withRouter(TableClient);